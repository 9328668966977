import { memo, FC } from 'react';
import cn from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import { Box } from 'uikitv2/Box';
import { FiltersDatasets } from './FiltersDatasets';
import { FiltersProps } from './types';
import classes from './Filters.module.scss';

export const Filters: FC<FiltersProps> = memo(({
  className, name, onSubmit, interval = 100,
}) => {
  const submitDebounced = useDebouncedCallback(onSubmit, interval);

  return (
    <Box className={cn(classes.wrap, className)} direction="column">
      <FiltersDatasets {...{
        name, onSubmit: submitDebounced,
      }}
      />
    </Box>
  );
});